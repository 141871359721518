import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const ProductSection = props => {
  const { broomHandle, molduras, tablero } = useStaticQuery(graphql`
    query {
      broomHandle: file(relativePath: { eq: "cabos-de-escoba-induba.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      molduras: file(relativePath: { eq: "molduras-frames-top-induba.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tablero: file(relativePath: { eq: "tablero-calidad-induba.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <section className="py-8 lg:py-16 px-4 lg:px-8" name="productos">
      <h3 className="text-center text-4xl font-extrabold mb-4 text-blue-800">
        {props.isEnglish ? 'Our Products' : 'Nuestros Productos'}
      </h3>
      <div className="flex justify-center flex-col items-center md:flex-row md:justify-between mx-auto">
        <Link
          to={
            props.isEnglish
              ? 'products/broom-handles'
              : 'productos/cabos-de-escoba'
          }
          className="m-4 w-full md:w-1/3"
        >
          <Img
            className="portrait"
            fluid={broomHandle.childImageSharp.fluid}
            fadeIn
            alt={props.isEnglish ? 'Broom Handles' : 'Cabo de Escoba'}
          />
          <span
            style={{ width: '240px' }}
            className="font-bold text-white bg-yellow-800 hover:bg-yellow-900 text-center block mx-auto py-4 px-8 mt-4 shadow-lg"
          >
            {props.isEnglish ? 'View Broom Handles' : 'Ver Cabo de Escoba'}
          </span>
        </Link>

        <Link
          to={props.isEnglish ? 'products/door-frames' : 'productos/molduras'}
          className="m-4 w-full md:w-1/3"
        >
          <Img
            className="portrait"
            fluid={molduras.childImageSharp.fluid}
            fadeIn
            alt={props.isEnglish ? 'Door Frames' : 'Molduras'}
          />
          <span
            style={{ width: '240px' }}
            className="font-bold text-white bg-yellow-800 hover:bg-yellow-900 text-center block mx-auto py-4 px-8 mt-4 shadow-lg"
          >
            {props.isEnglish ? 'View Door Frames' : 'Ver Molduras'}
          </span>
        </Link>
        <Link
          to={props.isEnglish ? 'products/wooden-boards' : 'productos/tableros'}
          className="m-4 w-full md:w-1/3"
        >
          <Img
            className="portrait"
            fluid={tablero.childImageSharp.fluid}
            fadeIn
            alt={props.isEnglish ? 'Pine Boards' : 'Tableros de Pino'}
          />
          <span
            style={{ width: '240px' }}
            className="font-bold text-white bg-yellow-800 hover:bg-yellow-900 text-center block mx-auto py-4 px-8 mt-4 shadow-lg"
          >
            {props.isEnglish ? 'View Pine Boards' : 'Ver Tableros de Pino'}
          </span>
        </Link>
      </div>
    </section>
  );
};

export default ProductSection;
