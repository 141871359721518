import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const HistorySection = props => {
  const { farm } = useStaticQuery(graphql`
    query {
      farm: file(
        relativePath: { eq: "induba-entrance-argentina-misiones.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <section className="py-10 lg:py-20">
      <div className="max-w-6xl flex justify-between mx-auto flex-col items-center md:flex-row p-4 md:p-8 pb-16">
        <div className="w-full lg:w-1/2 flex flex-col justify-center">
          <Img className="portrait" fluid={farm.childImageSharp.fluid} fadeIn />
        </div>
        <div className="w-full py-8 p-4 sm:pl-10 lg:w-1/2">
          <h2 className="text-4xl mb-4 leading-tight font-extrabold text-blue-800">
            {props.isEnglish ? 'Our History' : 'Nuestra Historia'}
          </h2>
          <p className="text-gray-800 text-xl">
            {props.isEnglish
              ? 'Composed of 5 members, the marriage of the agro-forestal engineer Raúl Badaracco and Inés Fernández Dos Santos, and their children Guillermo and Tulio, who maintain the day-to-day operations of Induba, and Marcelo, who is in charge of the agro-forestry division.'
              : 'Compuesta hoy por sus cinco miembros, el matrimonio integrado por el Ingeniero Agrónomo-Forestal Raúl Badaracco e Inés Fernández Dos Santos, sus hijos Guillermo y Tulio Badaracco, quienes se encuentran al sector industrial y, Marcelo Badaracco, a cargo del sector agro-forestal.'}
          </p>
          <Link
            to={props.isEnglish ? '/en/history/' : '/historia/'}
            className="font-bold text-white bg-yellow-800 hover:bg-yellow-900 py-4 px-8 inline-block mt-4 shadow-lg"
          >
            {props.isEnglish ? 'Read More' : 'Leer Más'}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HistorySection;
