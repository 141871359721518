import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

const Hero = props => {
  const { farm } = useStaticQuery(graphql`
    query {
      farm: file(relativePath: { eq: "argentina-misiones-farm-induba.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <section>
      <div className="flex justify-between max-w-6xl mx-auto flex-col md:flex-row py-8 pb-16">
        <div className="w-full p-4 lg:py-8 lg:pl-0 lg:pr-6 lg:w-2/5 flex flex-col justify-center">
          <h1 className="text-5xl md:mt-10 leading-tight font-extrabold text-teal-700">
            Induba SRL
          </h1>
          <h2 className="text-gray-800 text-xl">
            {props.isEnglish
              ? 'A woodmill in Misiones, Argentina, providing sustainable wood products to Europe and the Americas.'
              : 'Un aserradero en Misiones, Argentina que provee madera Sustentabilidad a Europa y las Américas.'}
          </h2>
          <div className="mt-4">
            <Link
              to={props.isEnglish ? '/en/contact/' : '/contacto/'}
              className="font-bold text-white bg-teal-600 hover:bg-teal-500 p-4 inline-block mt-4 shadow-lg"
            >
              {props.isEnglish ? 'Contact Us' : 'Solicita un presupuesto'}
            </Link>
            <Link
              to={props.isEnglish ? '/' : '/en'}
              className="text-sm text-gray-500 py-4 px-6 inline-block mt-4 underline"
            >
              {props.isEnglish ? 'Cambia a Español' : 'Change to English'}
            </Link>
          </div>
        </div>
        <div className="w-full p-4 lg:py-4 lg:pl-8 lg:pr-0 lg:w-3/5 flex flex-col justify-center">
          <Img className="portrait" fluid={farm.childImageSharp.fluid} fadeIn />
        </div>
      </div>
    </section>
  );
};

export default Hero;
