import React, { useEffect, useState } from 'react';
import LayoutEnglish from './../../components/layout-en';
import Hero from './../../components/hero';
import ProductSection from './../../components/products-section';
// import Sustainability from './../../components/sustainability';
import HistorySection from './../../components/history-section';

export default () => {
  const [isEnglish, setLang] = useState(false);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const href = window.location.href.split('/');
      setLang(href.includes('en'), []);
    }
  }, []);

  return (
    <LayoutEnglish>
      <Hero isEnglish={isEnglish} />
      <ProductSection isEnglish={isEnglish} />
      {/* <Sustainability /> */}
      <HistorySection isEnglish={isEnglish} />
    </LayoutEnglish>
  );
};
